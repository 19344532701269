import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import ScrollDivider from "../components/ScrollDivider";

// eslint-disable-next-line
export const PartnersPageTemplate = ({
  image,
  title,
  subheading,
  heading,
  color,
  description,
  partners,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage
        img={heroImage}
        title={title}
        subheading={subheading}
        height={"90vh"}
        color={color}
        imgPosition={"center 70%"}
      />
      <ScrollDivider color={color} button={true} anchor="programma#main" />
      <section
        className="section section--gradient"
        style={{ background: color }}
      >
        <div className="container" id="main">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-3 has-text-centered is-uppercase">
                        {heading}
                      </h3>
                      <p className="theme-italic has-text-centered">
                        {description}
                      </p>
                      {partners &&
                        partners.map((partner, i) => (
                          <a
                            className="columns"
                            style={{ alignItems: "center", columnGap: "1rem" }}
                            href={partner.url}
                            key={i}
                          >
                            <div className="column is-4">
                              <div className="featured-image">
                                <img src={partner.image} alt={partner.title} />
                              </div>
                            </div>
                            <div className="column is-8">
                              <h2 className="title is-size-3">
                                {partner.title}
                              </h2>
                              <div className="theme-body has-text-white">
                                {partner.description}
                              </div>
                            </div>
                          </a>
                        ))}
                      <p className="theme-italic has-text-centered">
                        Uw naam ook hier? <br />
                        Neem contact op met ons via{" "}
                        <a href="mailto:extern@skadilustrum.nl">
                          extern@skadilustrum.nl
                        </a>{" "}
                        om de mogelijkheden te bespreken.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

PartnersPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  partners: PropTypes.array,
};

const PartnersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout navColor={frontmatter.color}>
      <PartnersPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        color={frontmatter.color}
        partners={frontmatter.partners}
      />
    </Layout>
  );
};

PartnersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PartnersPage;

export const partnersPageQuery = graphql`
  query PartnersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        color
        heading
        description
        partners {
          title
          description
          image
          type
          url
        }
      }
    }
  }
`;
